<template>
  <div v-bind:class="{ isWrap: isWrap }" class="cartproduct">
    <div
      class="prod-image-disp"
      :style="{
        width: '100px',
        'background-image': 'url(' + helpers.getImage(data.PrimaryImage, 'PRODUCT') + ')',
      }"
    ></div>

    <div class="content" style="width: calc(100% - 110px)">
      <!--  <span class="time">15 minutes ago</span> -->
      <h3>{{ helpers.getAttribute(data, $t.getLanguage(), "Name") }}</h3>

      <div v-if="isSale" style="display: flex; align-items: center">
        <!-- <h4 class="sale-price">{{ helpers.formatPrice(data.Price.RegularSellingPrice, data.Price.CurrencyCode) }}</h4> -->
        <h4>
          {{ helpers.formatPrice(data.ProductSaleAmount, data.Price.CurrencyCode) }}
        </h4>
      </div>
      <div
        v-else-if="!isSale && data.Price.DiscountPrice"
        style="display: flex; align-items: center"
      >
        <!-- <h4 class="sale-price">{{ helpers.formatPrice(data.Price.RegularSellingPrice, data.Price.CurrencyCode) }}</h4> -->
        <h4>
          {{ helpers.formatPrice(data.Price.DiscountPrice, data.Price.CurrencyCode) }}
        </h4>
      </div>
      <h4 v-else>
        {{ helpers.formatPrice(data.Price.RegularSellingPrice, data.Price.CurrencyCode) }}
      </h4>

      <div v-if="data?.PackingQty?.Total && !data?.IsBundled">
        <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width /> x
        {{ data.PackingQty.Total }}
      </div>

      <div>
        <ProductInventoryStatus
          :showqty="data.Qty > order.SellableQty"
          :product="order"
        />
      </div>

      <div v-if="isSale">
        <span style="color: #0c4d8f; font-weight: bold">{{
          $t.getTranslation("LBL_ITEM_ON_SALE")
        }}</span>
      </div>

      <div v-if="isSale && props?.data?.QtyPerUser > 0">
        <span
          >{{ $t.getTranslation("LBL_QTY_CAN_ADD_TO_CART") }} -
          <font-awesome-icon :icon="['fal', 'wine-bottle']" fixed-width />
          {{ data.QtyPerUser }}
        </span>
      </div>

      <f7-stepper
        v-if="order.SellableQty > 0"
        v-model:value="FormData.Qty"
        :max="order.SellableQty"
        :min="0"
        :step="1"
        small
        color="black"
        class="cartstepper"
        @stepper:change="qtyChange"
      ></f7-stepper>

      <font-awesome-icon
        class="cartdelete"
        :icon="['far', 'times']"
        fixed-width
        @click="removeItem"
      />
    </div>

    <div v-if="data?.Moq > 1" class="moq-status">
      <p>{{ renderMoqMessage(data) }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, inject, ref, defineAsyncComponent } from "vue";
import { helpers } from "@/utils/helpers.js";
import { post } from "@/utils/axios";
import { useStore } from "@/store";

// import ProductInventoryStatus from '@/components/status/ProductInventoryStatus.vue'

const ProductInventoryStatus = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "product-inventory" */ /* webpackMode: "lazy" */ "@/components/status/ProductInventoryStatus.vue"
  )
);

export default defineComponent({
  name: "CartProductCardComponent",
  components: {
    ProductInventoryStatus,
  },
  props: { data: Object, check: Function, order: Object },
  setup(props) {
    const store = useStore();
    let FormData = reactive({ Qty: props.data.Qty });

    const $t = inject("$translation");
    const isWrap = ref(false);
    const isSale = ref(false);

    if (props.data.Moq > 1) {
      isWrap.value = true;
    }

    //check if the items if the sale is starting..
    if (props?.data?.ProductSaleStatus == "ACTIVE") {
      //check the startDate...
      let currentDate = new Date().getTime();
      let startTime = new Date(props?.data?.SaleFrom).getTime();
      let endTime = new Date(props?.data?.SaleTo).getTime();
      if (currentDate > startTime && !(currentDate > endTime)) {
        isSale.value = true;
      }
    }

    const updateQty = (ret) => {
      let find = _.find(ret.Cart.ItemList, (r) => {
        return r.ProductKey == props.data.ProductKey;
      });
      if (find) {
        FormData.Qty = find.Qty; //change???
      }
    };

    const qtyChange = async (val) => {
      if (val == 0) {
        return removeItem();
      }
      let ret = await post("/cart/item/update", {
        ProductKey: props.data.ProductKey,
        Qty: val,
      });
      if (!ret) return;
      updateQty(ret);
      props.check();
      store.dispatch("cart/setData", { CartItems: ret.Cart.ItemList || [] });
    };

    const renderMoqMessage = (item) => {
      let message = $t.getTranslation("LBL_FIXED_QTY_MSG");
      message = message.replace("{Qty}", item.Moq);
      return message;
    };

    const removeItem = async () => {
      let ret = await post("/cart/item/remove", { ProductKey: props.data.ProductKey });
      if (!ret) return;
      props.check();
      store.dispatch("cart/setData", { CartItems: ret.Cart.ItemList || [] });
    };

    return {
      FormData,
      helpers,
      qtyChange,
      removeItem,
      renderMoqMessage,
      isWrap,
      isSale,
    };
  },
});
</script>

<style scored>
.moq-status {
  padding: 15px 20px;
  background: var(--f7-theme-color);
  text-align: center;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
}
.moq-status > p {
  margin: 0px;
  font-size: 13px;
  color: #fff;
  line-height: 1.8em;
}

.isWrap {
  flex-wrap: wrap;
}
</style>
